import React from 'react'
import { connect } from "react-redux";

class UsersComponent extends React.Component {

    render() {

        let context = this;

        function getSelectValues(select) {
            var result = [];
            var options = select && select.options;
            var opt;

            for (var i = 0, iLen = options.length; i < iLen; i++) {
                opt = options[i];

                if (opt.selected) {
                    result.push(context.props.users.find(o => o.userId == opt.value));
                }
            }
            console.log(result)
            context.props.updateLineItems(context.props.prop, result)
        }

        console.log(this.props.users);
        return (
            <div className="form-group">
                <label>{this.props.header}</label>
                <div>
                    <select onChange={(e) => { getSelectValues(e.currentTarget) }} multiple className="custom-select">
                        {this.props.users && this.props.users.filter(u => !u.disabled).map(i => <option value={i.userId} selected={this.props.lineItems && !!this.props.lineItems.find(o => o.userId == i.userId)}>{i.name}{' '}{i.surname}</option>)}
                    </select>
                </div>
            </div>);
    };
}

const mapStateToProps = state => ({
    users: state.companies.company.users,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(UsersComponent)
